import React from 'react';
import { Helmet as ReactHelmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const site = process.env.REACT_APP_SITE;

const Helmet = () => {
  const { t, i18n } = useTranslation();

  return (
    <ReactHelmet htmlAttributes={{ lang: i18n.language }}>
      <title>
        {site} | {t('title')}
      </title>
      <meta name="description" content={t('description') || ''} />
    </ReactHelmet>
  );
};

export default Helmet;
